<template>
    <!-- footer Start -->
   <footer class="footer-area">
      <div class="footer-menu-2">
        <ul>
          <li><a href="https://www.fo-kids.co.jp/policy/" target="_blank">プライバシーポリシー</a> &nbsp;&nbsp;｜&nbsp;&nbsp;</li>
          <li><a href="rules" >特定商取引に基づく表示</a>&nbsp;&nbsp;｜&nbsp;&nbsp;</li>
          <li><a href="https://www.fo-kids.co.jp/company/outline/" target="_blank">会社概要</a>&nbsp;&nbsp;｜&nbsp;&nbsp;</li>
          <li><a href="mailto:algy-luckybag@rsv.fobz.jp?subject=ALGY福袋お問合せ" target="_blank">お問合せ</a></li>
        </ul>
      </div>
      <hr class="hr-footer" />
      <p class="copyright">© F.O.INTERNATIONAL CO., LTD.</p>
    </footer>
    <!--  footer Area End -->
</template>

<script>

export default {
  name: 'Footer'
}

</script>

<style>
</style>
