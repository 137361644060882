<template>
  <div>
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"

window.dataLayer = window.dataLayer || []
function gtag() { window.dataLayer.push(arguments) }
gtag('js', new Date())
gtag('config', 'UA-33303356-3')
gtag('config', 'G-EZT7K2LJEN')

export default {
  name: "ClientBase",
  components: {
    Header,
    Footer
  },
   data(){
     return {
     }
   },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
</style>
