<template>
    <!-- Header Area Start -->
    <header class="main-header">
      <div class="inner">
        <img src="../assets/images/logo/Logo-FO-text-back-white.png" class="img" />
      </div>
    </header>
    <!--  Header Area End -->
</template>

<script>

export default {
  name: 'Header',
}

</script>

<style>
</style>
