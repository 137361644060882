var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-card"},[_vm._m(0),(_vm.lstOrderHistory !== null && _vm.lstOrderHistory.length > 0)?_c('div',{staticStyle:{"padding-top":"10px"}},_vm._l((_vm.lstOrderHistory),function(orderhistory,index){return _c('div',{key:index},[(index === 0)?_c('div',[(orderhistory.histType === _vm.HIST_TYPE_CHANGE_STATUS &&
                                                orderhistory.updatedStatus === _vm.RSV_STATUS_ORDER)?_c('div',[_c('b-row',{staticStyle:{"padding-bottom":"10px"}},[_c('b-col',{staticClass:"fix-padding",staticStyle:{"padding-left":"80px","margin-top":"-4px","font-size":"20px","color":"#00bcd4"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v("・")]),_c('b-col',{staticClass:"fix-padding",staticStyle:{"max-width":"180px"},attrs:{"xl":"3","lg":"3","md":"3"}},[_vm._v(_vm._s(_vm.moment( _vm.lstOrderHistory[index].updateDtTm ).format("YYYY-MM-DD HH:mm"))+" ")]),(
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_QUANTITY||
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE ||
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_COLOR||
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_QUANTITY ||
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_COLOR_AND_QUANTITY ||
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR ||
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR_AND_QUANTITY ||
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_RECIVE_INFO ||
                            orderhistory.histType === _vm.HIST_TYPE_CHANGE_SHOP ||
                            orderhistory.histType ===  _vm.HIST_TYPE_DELETE_ORDER )?_c('b-col',{staticClass:"fix-padding",attrs:{"xl":"7","lg":"7","md":"7"}},[_c('b-row',{staticStyle:{"margin-left":"unset","margin-top":"1px"}},[_vm._v(" 本部が下記の内容を変更しました。 ")]),_c('b-row',{staticStyle:{"margin-left":"unset"}},[_c('b-col',{staticStyle:{"padding-right":"unset"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v(" ・ ")]),_c('b-col',{staticStyle:{"padding-left":"unset"}},[_vm._v(" "+_vm._s(_vm.setDisplayStrHist( orderhistory.histType, orderhistory ))+" ")])],1)],1):_c('b-col',{staticClass:"fix-padding",attrs:{"xl":"7","lg":"8","md":"8"}},[_vm._v(" "+_vm._s(_vm.setDisplayStrHist( orderhistory.histType, orderhistory))+" ")])],1)],1):_c('div',[_c('b-row',{staticStyle:{"padding-bottom":"10px"}},[_c('b-col',{staticClass:"fix-padding",staticStyle:{"padding-left":"80px","margin-top":"-4px","font-size":"20px","color":"#00bcd4"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v("・")]),_c('b-col',{staticClass:"fix-padding",staticStyle:{"max-width":"180px"},attrs:{"xl":"3","lg":"3","md":"3"}},[_vm._v(_vm._s(_vm.moment( _vm.lstOrderHistory[index].updateDtTm ).format("YYYY-MM-DD HH:mm"))+" ")]),(
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_QUANTITY||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_COLOR||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_COLOR_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_RECIVE_INFO ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SHOP ||
                        orderhistory.histType ===  _vm.HIST_TYPE_DELETE_ORDER )?_c('b-col',{staticClass:"fix-padding",attrs:{"xl":"7","lg":"7","md":"7"}},[_c('b-row',{staticStyle:{"margin-left":"unset","margin-top":"1px"}},[_vm._v(" 本部が下記の内容を変更しました。 ")]),_c('b-row',{staticStyle:{"margin-left":"unset"}},[_c('b-col',{staticStyle:{"padding-right":"unset"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v(" ・ ")]),_c('b-col',{staticStyle:{"padding-left":"unset"}},[_vm._v(" "+_vm._s(_vm.setDisplayStrHist( orderhistory.histType, orderhistory ))+" ")])],1)],1):_c('b-col',{staticClass:"fix-padding",attrs:{"xl":"7","lg":"8","md":"8"}},[_vm._v(" "+_vm._s(_vm.setDisplayStrHist( orderhistory.histType, orderhistory))+" ")])],1)],1)]):_c('div',[(orderhistory.histType === _vm.HIST_TYPE_CHANGE_STATUS &&
                                                        orderhistory.updatedStatus === _vm.RSV_STATUS_ORDER)?_c('div',[_c('b-row',{staticStyle:{"padding-bottom":"10px"}},[(
                                _vm.moment(
                                    _vm.lstOrderHistory[index].updateDtTm
                                ).format('YYYY-MM-DD HH:mm') !==
                                    _vm.moment(
                                    _vm.lstOrderHistory[index - 1].updateDtTm
                                    ).format('YYYY-MM-DD HH:mm')
                                )?_c('b-col',{staticClass:"fix-padding",staticStyle:{"padding-left":"80px","margin-top":"-4px","font-size":"20px","color":"#00bcd4"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v("・")]):_c('b-col',{staticClass:"fix-padding",staticStyle:{"padding-left":"80px","margin-top":"-4px","font-size":"20px","color":"#00bcd4"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v("・")]),(
                                    _vm.moment(
                                    _vm.lstOrderHistory[index].updateDtTm
                                    ).format('YYYY-MM-DD HH:mm') !==
                                    _vm.moment(
                                        _vm.lstOrderHistory[index - 1]
                                        .updateDtTm
                                    ).format('YYYY-MM-DD HH:mm')
                                )?_c('b-col',{staticClass:"fix-padding",staticStyle:{"max-width":"180px"},attrs:{"xl":"3","lg":"3","md":"3"}},[_vm._v(_vm._s(_vm.moment( _vm.lstOrderHistory[index].updateDtTm ).format("YYYY-MM-DD HH:mm"))+" ")]):_c('b-col',{staticClass:"fix-padding",staticStyle:{"max-width":"180px"},attrs:{"xl":"3","lg":"3","md":"3"}},[_vm._v(" "+_vm._s(_vm.moment( _vm.lstOrderHistory[index - 1] .updateDtTm ).format('YYYY-MM-DD HH:mm'))+" ")]),_c('b-col',{staticClass:"fix-padding",attrs:{"xl":"7","lg":"7","md":"7"}},[_vm._v(" "+_vm._s(_vm.setDisplayStrHist( orderhistory.histType, orderhistory ))+" ")])],1)],1):_c('div',[_c('b-row',{staticStyle:{"padding-bottom":"10px"}},[(
                            _vm.moment(
                            _vm.lstOrderHistory[index].updateDtTm
                            ).format('YYYY-MM-DD HH:mm') !==
                            _vm.moment(
                                _vm.lstOrderHistory[index - 1].updateDtTm
                            ).format('YYYY-MM-DD HH:mm')
                        )?_c('b-col',{staticClass:"fix-padding",staticStyle:{"padding-left":"80px","margin-top":"-4px","font-size":"20px","color":"#00bcd4"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v("・")]):_c('b-col',{staticClass:"fix-padding",staticStyle:{"padding-left":"80px","margin-top":"-4px","font-size":"20px","color":"#00bcd4"},attrs:{"xl":"1","lg":"1","md":"1"}}),(
                        _vm.moment(
                            _vm.lstOrderHistory[index].updateDtTm
                        ).format('YYYY-MM-DD HH:mm') !==
                            _vm.moment(
                            _vm.lstOrderHistory[index - 1]
                                .updateDtTm
                            ).format('YYYY-MM-DD HH:mm')
                        )?_c('b-col',{staticClass:"fix-padding",staticStyle:{"max-width":"180px"},attrs:{"xl":"3","lg":"3","md":"3"}},[_vm._v(_vm._s(_vm.moment( _vm.lstOrderHistory[index].updateDtTm ).format("YYYY-MM-DD HH:mm"))+" ")]):_c('b-col',{staticClass:"fix-padding",staticStyle:{"max-width":"180px"},attrs:{"xl":"3","lg":"3","md":"3"}}),(
                        (orderhistory.histType === _vm.HIST_TYPE_CHANGE_QUANTITY||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_COLOR||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_COLOR_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_RECIVE_INFO ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SHOP ||
                        orderhistory.histType === _vm.HIST_TYPE_DELETE_ORDER) &&
                    ((_vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_QUANTITY&&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_SIZE &&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_COLOR &&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_SIZE_AND_QUANTITY &&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_COLOR_AND_QUANTITY &&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR &&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR_AND_QUANTITY &&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_RECIVE_INFO &&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_CHANGE_SHOP &&
                        _vm.lstOrderHistory[index - 1].histType !== _vm.HIST_TYPE_DELETE_ORDER)
                    || _vm.moment(
                            _vm.lstOrderHistory[index].updateDtTm
                        ).format('YYYY-MM-DD HH:mm') !==
                            _vm.moment(
                            _vm.lstOrderHistory[index - 1]
                                .updateDtTm
                            ).format('YYYY-MM-DD HH:mm')))?_c('b-col',{staticClass:"fix-padding",attrs:{"xl":"7","lg":"7","md":"7"}},[_c('b-row',{staticStyle:{"margin-left":"unset","margin-top":"1px"}},[_vm._v(" 本部が下記の内容を変更しました。 ")]),_c('b-row',{staticStyle:{"margin-left":"unset"}},[_c('b-col',{staticStyle:{"padding-right":"unset"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v(" ・ ")]),_c('b-col',{staticStyle:{"padding-left":"unset"}},[_vm._v(" "+_vm._s(_vm.setDisplayStrHist( orderhistory.histType, orderhistory ))+" ")])],1)],1):_c('b-col',{staticClass:"fix-padding",attrs:{"xl":"7","lg":"7","md":"7"}},[( (orderhistory.histType === _vm.HIST_TYPE_CHANGE_QUANTITY||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_COLOR||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_COLOR_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SIZE_AND_COLOR_AND_QUANTITY ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_RECIVE_INFO ||
                        orderhistory.histType === _vm.HIST_TYPE_CHANGE_SHOP ||
                        orderhistory.histType ===  _vm.HIST_TYPE_DELETE_ORDER))?_c('b-row',{staticStyle:{"margin-left":"unset"}},[_c('b-col',{staticClass:"fix-padding",staticStyle:{"padding-right":"unset"},attrs:{"xl":"1","lg":"1","md":"1"}},[_vm._v(" ・ ")]),_c('b-col',{staticStyle:{"padding-left":"unset"}},[_vm._v(" "+_vm._s(_vm.setDisplayStrHist( orderhistory.histType, orderhistory ))+" ")])],1):_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(_vm.setDisplayStrHist( orderhistory.histType, orderhistory ))+" ")])],1)],1)],1)],1)])])}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-card__title font-weight-small white--text",staticStyle:{"border-radius":"5px","font-size":"17px","margin-bottom":"10px","background-color":"#00bcd4 !important"}},[_c('strong',[_vm._v("履歴")])])}]

export { render, staticRenderFns }